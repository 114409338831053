.error-page {
    text-align: center;
}

.oops {
    font-size: 70px;
    font-weight: bold;
    margin-bottom: 10px;
}

.error-message {
    font-size: 40px;
}
