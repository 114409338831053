.nav-menu {
  background-color: #011f41;
  width: 232px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.menu-bars {
  width: 100%;
  height: 80px;
  margin: 0px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-items {
  width: 100%;
}
